import { createUseStyles } from "react-jss"


const useStyle = createUseStyles({
    titleCard: {
        margin: "25px 0 !important",
        fontSize: "16px",
        fontWeight: "600",
        textTransform: "uppercase",
        color: "white !important",
        letterSpacing: "1.2px"

    },
    subtitleCard: {
        color: "white !important",
        textTransform: "uppercase",
        fontWeight: "600",
        fontSize: "14px",
    }
})

const Footer = () => {
    const classes = useStyle();
    return (
        <>
            <div className="row d-flex justify-content-center footer-bg">
                <div className="col curved-left  "></div>
            </div>
            <div className="row d-flex justify-content-center ">
                <div className="col curved-right"></div>
            </div>
            <div className="row d-flex justify-content-center p-0 footer-bg footer-text-color">
                <div className="col-md-8">
                    <div className="row p-0">
                        <div className="col-md-3 mt-3 mb-3">
                            <h3 className={`${classes.titleCard}`}>Contact Info</h3>
                            <div className="underlineTitleFooter" />
                            <div className="mb-3">
                                <h4 className={`${classes.subtitleCard}`}>Phone</h4>
                                <span className="linkFooter">1300880919</span>
                            </div>
                            <div className="mb-3">
                                <h4 className={`${classes.subtitleCard}`}>Address</h4>
                                <span className="linkFooter"> <span className="d-block">Sunshine Private Hospital</span>
                                    <span className="d-block">Level 2, Suite 2.3</span>
                                    <span className="d-block">145 Furlong Rd</span>
                                    <span className="d-block">St Albans, VIC 3021</span></span>
                            </div>
                            <div className="mb-3">
                                <h4 className={`${classes.subtitleCard}`}>Email</h4>
                                <span className="linkFooter">info@DrKaiLeeOMS.com.au</span>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3 mb-3" style={{ padding: "0 25px" }}>
                            <h3 className={`${classes.titleCard}`}>Services</h3>
                            <div className="underlineTitleFooter" />
                            <div className="">
                                <a className={`linkFooter`} href={"/services/surgical-extractions"} title="dental-surgical-extraction-pages" >Surgical Extraction</a>
                                <a className={`linkFooter`} href={"/services/wishdom-teeth"} title="wishdom-teeth-pages">Wisdom Teeth</a>
                                <a className={`linkFooter`} href={"/services/odontogenic-cysts"} title="odontogenic-cysts-pages">Odontogenic Cysts</a>
                                <a className={`linkFooter`} href={"/services/facial-trauma"} title="facial-trauma-pages">Facial Trauma</a>
                                <a className={`linkFooter`} href={"/services/corrective-jaw-surgery"} title="corrective-jaw-surgery-pages">Corrective Jaw Surgery</a>
                                <a className={`linkFooter`} href={"/services/dental-implants"} title="dental-implants-pages">Dental Implants</a>
                                <a className={`linkFooter`} href={"/services/maxilary-sinus-surgery"} title="maxillary-sinus-pages">Maxillary Sinus Surgery</a>
                                <a className={`linkFooter`} href={"/services/preprotechetic-surgery"} title="preprosthetic-surgery-pages">Preprosthetic Surgery</a>
                            </div>

                        </div>
                        <div className="col-md-3 mt-3 mb-3" style={{ padding: "0 25px" }}>
                            <h3 className={`${classes.titleCard}`}>For Patients</h3>
                            <div className="underlineTitleFooter" />
                            <a className={`linkFooter`} href={"/registration-patient"} title="registration patient">Patient Registration</a>
                            <a className={`linkFooter`} href={"/pre-surgery-information"} title="pre surgery information">Pre Surgery Information</a>
                            <a className={`linkFooter`} href={"/post-surgery-information"} title="post surgery information">Post Surgery Information</a>
                            <a className={`linkFooter`} href={"/Faq"}>FAQ</a>
                            <a className={`linkFooter`} href={"/payment-options"} title="payment options ">Payment Options</a>
                        </div>
                        <div className="col-md-3 mt-3 mb-3" style={{ padding: "0 25px" }}>
                            <h3 className={`${classes.titleCard}`}>FOR REFERRING PRACTITIONERS</h3>
                            <div className="underlineTitleFooter" />
                            <a className={`linkFooter`} href={"/referal"} title="making referral practionnaire">Making Referral</a>
                            &copy; Copyright 2023 Westgate Surgical
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer